@import url(https://fonts.googleapis.com/css?family=Roboto);

.hieroglyph {
  display: grid;
  place-items: center;
  height: 100%;
  pointer-events: none;
}
.anaglyph {
  font-weight: bold;
  text-shadow: -0.03em 0px 2px red, 0.03em 0px 2px cyan;
  animation: anaglyph-glitch 2s infinite;
}
.card-wrapper {
  position: relative;
  display: grid;
  place-items: center;
  user-select: none;
  width: 25%;
}
.card {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  image-rendering: -webkit-optimize-contrast;
  z-index: 10;
  overflow: hidden;
}
.card-lg {
  width: 100px;
  height: 140px;
}
.card-m {
  width: 90px;
  height: 126px;
}
.card-s {
  width: 80px;
  height: 112px;
}
.card-xs {
  width: 60px;
  height: 84px;
}
.eliminated {
  cursor: default;
  pointer-events: none;
}
.not-eliminated {
  cursor: pointer;
  pointer-events: auto;
}
.card-shadow-xs {
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.75);
}
.card-shadow-s {
  box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.75);
}
.card-shadow-lg {
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.75);
}
.card-shine {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  z-index: 1;
  border-radius: 10px;
}
.card-front,
.card-title,
.card-subtitle {
  position: absolute;
  color: #fff;
  transform-style: preserve-3d;
}
.card-front {
  border-radius: 10px;
  width: inherit;
  height: inherit;
  z-index: 0;
  pointer-events: none;
}
.card-title {
  font-weight: 700;
  text-align: left;
  left: 10%;
  bottom: 70px;
  font-size: 1rem;
  text-shadow: 0 5px 8px rgba(0, 0, 0, 0.65);
  transform: translateZ(0px);
  overflow: hidden;
  margin: 0;
  width: 80%;
}
.card-subtitle {
  font-weight: normal;
  text-align: left;
  left: 10%;
  width: 80%;
  bottom: 10px;
  font-size: 0.75rem;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.8);
  transform: translateZ(0px);
}
@keyframes anaglyph-glitch {
  40% {
    text-shadow: -0.03em 0px 2px red, 0.03em 0px 2px cyan;
  }
  42.5% {
    text-shadow: -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }
  45% {
    text-shadow: -0.07em 0px 2px red, 0.07em 0px 2px cyan;
  }
  47.5% {
    text-shadow: -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }
  50% {
    text-shadow: 0.05em 0px 2px red, -0.05em 0px 2px cyan;
  }
  75.5% {
    text-shadow: -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }
  100% {
    text-shadow: -0.025em 0px 2px red, 0.025em 0px 2px cyan;
  }
}

.modal-container,
.cardgame-container {
  width: 85vw;
  max-width: 500px;
  border-radius: 0.5rem;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: #ffffff;
  animation-name: fadein;
  transition-duration: 1500ms;
}

.modal-container img,
.cardgame-container img {
  -webkit-user-drag: none;
}

.modal-container {
  animation-duration: 500ms;
  background-color: rgba(72, 72, 116, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Iceland;
  max-height: 90vh;
  overflow-y: auto;
}

.cardgame-container {
  animation-duration: 3000ms;
  background-color: rgba(103, 103, 169, 0.25);
  backdrop-filter: blur(4px);
  box-shadow: 0 10px 20px rgba(23, 23, 37, 0.19),
    0 6px 6px rgba(23, 23, 37, 0.23);
}

.cardgame-secondary-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Iceland;
}

.modal-button {
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  background-color: rgba(17, 168, 205, 1);
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-family: Iceland;
  user-select: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.modal-button:hover {
  background-color: rgb(18, 124, 150);
}

.cardgame-secondary-container img,
#teaser {
  animation: box-neon 2s infinite;
}

.cardgame-secondary-container p {
  animation: text-glitch 1s infinite;
}

.counter {
  font-family: Iceland;
  font-size: 2rem;
}

.counter-neon-game {
  animation: anaglyph-glitch 2s ease-in-out infinite alternate;
}

.counter-neon-score {
  animation: neon2 1.5s ease-in-out infinite alternate;
}

.container-sheet {
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  transition-duration: 1000ms;
  position: relative;
  padding-top: 15px;
  padding-bottom: 30px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.wrapper-absolute {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  transition-duration: 750ms;
}

.container-absolute {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.message-normal {
  font-family: Iceland;
  text-shadow: 0 0 3px #fff, 0 0 6px #0ff, 0 0 9px #0ff;
}

.message-signature {
  font-family: Iceland;
  text-shadow: 0 0 3px #fff, 0 0 6px #0ff, 0 0 9px #0ff;
}

.special-letter {
  font-weight: bold;
  margin-left: 0.1em;
  margin-right: 0.1em;
}

.neon-green {
  text-shadow: 0 0 3px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f;
  animation: neon-glitch-green 2s infinite;
}

.neon-red {
  text-shadow: 0 0 3px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f;
  animation: neon-glitch-red 2s infinite;
}

.login-input {
  font-family: Iceland;
  font-weight: bold;
  height: 35px;
  width: 240px;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  font-size: 2rem;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  box-shadow: 0 0 5px #fff, 0 0 10px rgba(15, 240, 255, 0.1),
    0 0 20px rgba(15, 240, 255, 0.1);
  animation: box-neon 2s infinite, text-glitch 1s infinite;
  text-shadow: -0.03em 0px 2px red, 0.03em 0px 2px cyan;
  text-align: center;
  margin-top: 30px;
  z-index: 50;
}

.cheat-overlay {
  width: auto;
  height: auto;
  padding: 10px;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  align-items: center;
}

.easter-egg-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.7rem;
  width: 100%;
}

.easter-egg-footer a {
  width: 15%;
  background-color: #fff;
  border-radius: 33%;
  display: grid;
  place-items: center;
}

.logo-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: nowrap;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
}

.logo-item {
  padding: 0.2rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 1);
  transition-duration: 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  width: min-content;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5), 0px 2px 8px rgba(0, 0, 0, 0.4);
}

.logo-item img {
  max-width: 70px;
  max-height: 70px;
  transition-duration: 200ms;
}

@keyframes neon-glitch-red {
  40% {
    text-shadow: 0 0 3px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f,
      -0.03em 0px 2px red, 0.03em 0px 2px cyan;
  }

  42.5% {
    text-shadow: 0 0 3px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f,
      -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }

  45% {
    text-shadow: 0 0 3px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f,
      -0.07em 0px 2px red, 0.07em 0px 2px cyan;
  }

  47.5% {
    text-shadow: 0 0 3px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f,
      -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }

  50% {
    text-shadow: 0 0 3px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f,
      0.05em 0px 2px red, -0.05em 0px 2px cyan;
  }

  75.5% {
    text-shadow: 0 0 3px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f,
      -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }

  100% {
    text-shadow: 0 0 5px #fff, 0 0 10px #ff0f0f, 0 0 20px #ff0f0f,
      -0.025em 0px 2px red, 0.025em 0px 2px cyan;
  }
}

@keyframes neon-glitch-green {
  40% {
    text-shadow: 0 0 3px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f,
      -0.03em 0px 2px red, 0.03em 0px 2px cyan;
  }

  42.5% {
    text-shadow: 0 0 3px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f,
      -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }

  45% {
    text-shadow: 0 0 3px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f,
      -0.07em 0px 2px red, 0.07em 0px 2px cyan;
  }

  47.5% {
    text-shadow: 0 0 3px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f,
      -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }

  50% {
    text-shadow: 0 0 3px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f,
      0.05em 0px 2px red, -0.05em 0px 2px cyan;
  }

  75.5% {
    text-shadow: 0 0 3px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f,
      -0.05em 0px 2px red, 0.05em 0px 2px cyan;
  }

  100% {
    text-shadow: 0 0 5px #fff, 0 0 10px #37ff0f, 0 0 20px #37ff0f,
      -0.025em 0px 2px red, 0.025em 0px 2px cyan;
  }
}

@keyframes box-neon {
  0% {
    box-shadow: 0 0 5px #fff, 0 0 10px rgba(15, 240, 255, 0.1),
      0 0 20px rgba(15, 240, 255, 0.1);
  }

  50% {
    box-shadow: 0 0 5px #fff, 0 0 10px rgba(161, 255, 146, 0.25),
      0 0 20px rgba(161, 255, 146, 0.25);
  }

  100% {
    box-shadow: 0 0 5px #fff, 0 0 10px rgba(15, 240, 255, 0.1),
      0 0 20px rgba(15, 240, 255, 0.1);
  }
}

@keyframes neon2 {
  from {
    text-shadow: 0 0 5px #fff, 0 0 6px #fff, 0 0 12px #fff, 0 0 15px #228dff,
      0 0 20px #228dff, 0 0 25px #228dff;
  }

  to {
    text-shadow: 0 0 3px #fff, 0 0 5px #fff, 0 0 7px #fff, 0 0 10px #228dff,
      0 0 12px #228dff, 0 0 13px #228dff;
  }
}

@keyframes text-glitch {
  40% {
    text-shadow: -0.04em 0px 4px red, 0.04em 0px 4px cyan;
  }

  42.5% {
    text-shadow: -0.06em 0px 4px red, 0.06em 0px 4px cyan;
  }

  45% {
    text-shadow: -0.07em 0px 4px red, 0.07em 0px 4px cyan;
  }

  47.5% {
    text-shadow: -0.06em 0px 4px red, 0.06em 0px 4px cyan;
  }

  50% {
    text-shadow: 0.06em 0px 4px red, -0.06em 0px 4px cyan;
  }

  75.5% {
    text-shadow: -0.06em 0px 4px red, 0.06em 0px 4px cyan;
  }

  100% {
    text-shadow: -0.027em 0px 4px red, 0.027em 0px 4px cyan;
  }
}

.modal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
}
